<template>
    <section
        v-if="filteredPromos && filteredPromos.length > 0 && showComponent"
        class="o-flexible-module"
        :class="[{ 'o-flexible-module--flush': componentData.settings.spacing == 'flush' }]">
        <div v-if="componentData.settings.show_component_title" class="grid-container">
            <div class="grid-x grid-margin-x">
                <div class="small-12 cell">
                    <div class="utl-heading-beta">Promo Slider</div>
                </div>
            </div>
        </div>

        <div
            class="c-promo-slider js-promo-slider"
            :class="[
                {
                    'has-reduced-height':
                        componentData.settings.reduce_component_height || componentData.content_source == 'page',
                },
            ]"
            :data-component-id="componentId"
            :data-promo-type="componentData.settings.promo_type_visibility">
            <div class="grid-container">
                <div class="grid-x grid-margin-x">
                    <div class="small-12 cell">
                        <div class="c-promo-slider__slider-wrapper s-swiper">
                            <div class="c-promo-slider__items">
                                <swiper-container
                                    v-if="filteredPromos.length > 0"
                                    ref="containerRef"
                                    :auto-height="true"
                                    :autoplay-delay="7000"
                                    :autoplay-pause-on-mouse-enter="true"
                                    :loop="true"
                                    :slides-per-view="1"
                                    :space-between="40">
                                    <swiper-slide v-for="(promo, index) in filteredPromos" :key="index">
                                        <NuxtLink
                                            v-if="promo.link"
                                            class="c-promo-slider__item"
                                            :class="[
                                                `t-${promo.settings.theme}`,
                                                { 'has-media-right': promo.settings.media_placement == 'right' },
                                            ]"
                                            noPrefetch
                                            :to="promo.link?.url"
                                            :target="promo.link.target">
                                            <div class="grid-x grid-margin-x align-middle align-center">
                                                <div
                                                    class="small-12 medium-6 cell"
                                                    :class="[
                                                        promo.settings.media_placement == 'right'
                                                            ? 'medium-order-2 medium-offset-1'
                                                            : '',
                                                    ]">
                                                    <div
                                                        class="c-promo-slider__item-media"
                                                        :class="[{ 'is-desktop': promo.mobile_image }]"
                                                        v-html="promo?.image?.attachments['large']"></div>

                                                    <div
                                                        v-if="promo.mobile_image"
                                                        class="c-promo-slider__item-media is-mobile"
                                                        v-html="promo?.mobile_image?.attachments['large']"></div>
                                                </div>

                                                <div
                                                    class="small-12 medium-5 cell"
                                                    :class="[
                                                        promo.settings.media_placement == 'right'
                                                            ? 'medium-order-1'
                                                            : 'medium-offset-1',
                                                    ]">
                                                    <div class="c-promo-slider__item-content-wrapper">
                                                        <template v-if="promo.title">
                                                            <h2
                                                                v-if="promo.settings.heading_level == 'h2'"
                                                                class="c-promo-slider__item-title wow animate__animated animate__fadeIn"
                                                                v-html="promo.title"></h2>

                                                            <h3
                                                                v-else-if="promo.settings.heading_level == 'h3'"
                                                                class="c-promo-slider__item-title wow animate__animated animate__fadeIn"
                                                                v-html="promo.title"></h3>

                                                            <div
                                                                v-else
                                                                class="c-promo-slider__item-title wow animate__animated animate__fadeIn"
                                                                v-html="promo.title"></div>
                                                        </template>

                                                        <div
                                                            v-if="promo.content"
                                                            class="c-promo-slider__item-content s-wp-cms wow animate__animated animate__fadeIn"
                                                            v-html="promo.content"></div>

                                                        <div
                                                            class="c-promo-slider__item-link wow animate__animated animate__fadeIn">
                                                            <span v-html="promo.link.title"></span>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </NuxtLink>

                                        <div
                                            v-else
                                            class="c-promo-slider__item"
                                            :class="[
                                                `t-${promo.settings.theme}`,
                                                { 'has-media-right': promo.settings.media_placement == 'right' },
                                            ]">
                                            <div class="grid-x grid-margin-x align-middle align-center">
                                                <div
                                                    class="small-12 medium-6 cell"
                                                    :class="[
                                                        promo.settings.media_placement == 'right'
                                                            ? 'medium-order-2 medium-offset-1'
                                                            : '',
                                                    ]">
                                                    <div
                                                        class="c-promo-slider__item-media"
                                                        :class="[{ 'is-desktop': promo.mobile_image }]"
                                                        v-html="promo?.image?.attachments['large']"></div>

                                                    <div
                                                        v-if="promo.mobile_image"
                                                        class="c-promo-slider__item-media is-mobile"
                                                        v-html="promo?.mobile_image?.attachments['large']"></div>
                                                </div>

                                                <div
                                                    class="small-12 medium-5 cell"
                                                    :class="[
                                                        promo.settings.media_placement == 'right'
                                                            ? 'medium-order-1'
                                                            : 'medium-offset-1',
                                                    ]">
                                                    <div class="c-promo-slider__item-content-wrapper">
                                                        <template v-if="promo.title">
                                                            <h2
                                                                v-if="promo.settings.heading_level == 'h2'"
                                                                class="c-promo-slider__item-title wow animate__animated animate__fadeIn"
                                                                v-html="promo.title"></h2>

                                                            <h3
                                                                v-else-if="promo.settings.heading_level == 'h3'"
                                                                class="c-promo-slider__item-title wow animate__animated animate__fadeIn"
                                                                v-html="promo.title"></h3>

                                                            <div
                                                                v-else
                                                                class="c-promo-slider__item-title wow animate__animated animate__fadeIn"
                                                                v-html="promo.title"></div>
                                                        </template>

                                                        <div
                                                            v-if="promo.content"
                                                            class="c-promo-slider__item-content s-wp-cms wow animate__animated animate__fadeIn"
                                                            v-html="promo.content"></div>

                                                        <NuxtLink
                                                            v-if="promo.link"
                                                            noPrefetch
                                                            :to="promo.link?.url"
                                                            :target="promo.link.target"
                                                            class="c-promo-slider__item-link wow animate__animated animate__fadeIn">
                                                            <span v-html="promo.link.title"></span>
                                                        </NuxtLink>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </swiper-slide>
                                </swiper-container>

                                <div
                                    v-if="filteredPromos.length > 1"
                                    class="swiper-pagination js-swiper-pagination"></div>

                                <swiper-navigation v-if="filteredPromos.length > 1 && swiper" class="swiper-navigation">
                                    <button
                                        @click="swiper.prev()"
                                        class="swiper-button-prev js-swiper-button-prev"
                                        aria-label="Previous slide"
                                        type="button">
                                        <i class="fa-sharp fa-chevron-left"></i>
                                    </button>

                                    <button
                                        @click="swiper.next()"
                                        class="swiper-button-next js-swiper-button-next"
                                        aria-label="Next slide"
                                        type="button">
                                        <i class="fa-sharp fa-chevron-right"></i>
                                    </button>
                                </swiper-navigation>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </section>
</template>

<script setup>
    import { computed, watch } from "vue";
    import moment from "moment";

    const props = defineProps({
        componentData: {
            type: Object,
            required: true,
        },
        useStore: {
            type: String,
            required: false,
        },
    });

    const route = useRoute();
    const componentId = useId();
    const containerRef = ref(null);
    const swiper = useSwiper(containerRef);
    const displayedPromos = ref(null);
    const siteStore = useSiteStore();

    const showComponent = computed(() => {
        const topLevelVisiblity = props.componentData.settings.promo_type_visibility;
        const holidayFilterState = siteStore.state.holidayFilterState || siteStore.state.holidayFilterStateBarOnly;

        if (topLevelVisiblity === "both") {
            return true;
        }

        return topLevelVisiblity === holidayFilterState;
    });

    const filteredPromos = computed(() => {
        const currentDate = moment();
        const holidayFilterState = props.useStore === false ? null : siteStore.state.holidayFilterState;

        if (displayedPromos.value === null) {
            return [];
        }

        return displayedPromos.value.filter((promo) => {
            const slideLevelVisibility = promo.settings.promo_type;
            const matchesType =
                slideLevelVisibility.includes(holidayFilterState) ||
                !holidayFilterState ||
                slideLevelVisibility.length === 0;

            let isActive = true;

            if (promo.settings.start_date) {
                isActive = isActive && moment(promo.settings.start_date, "DD/MM/YYYY").isSameOrBefore(currentDate);
            }

            if (promo.settings.end_date) {
                isActive = isActive && moment(promo.settings.end_date, "DD/MM/YYYY").isSameOrAfter(currentDate);
            }

            return matchesType && isActive;
        });
    });

    const getPagePromos = async () => {
        const promos = [];

        const { data } = await useFetch(`/api/wp/promos/promo`);

        if (data) {
            // Removes both leading and trailing slashes
            const fullPath = route.path.replace(/^\/|\/$/g, "");

            data.value.forEach((item) => {
                if (item.acf.settings.pages) {
                    item.acf.settings.pages.forEach((page) => {
                        if ("page_path" in page) {
                            const pagePath = page.page_path.replace(/^\/|\/$/g, "");

                            if (pagePath === fullPath) {
                                promos.push({
                                    ...item.acf,
                                    ...(item.mobile_image && { mobile_image: item.mobile_image }),
                                });
                            }
                        }
                    });
                }
            });
        }

        return promos;
    };

    const getDisplayedPromos = async (promoType, source) => {
        let promos = null;

        const currentDate = moment();

        if (source == "page") {
            const pagePromos = await getPagePromos();

            promos = pagePromos;
        } else {
            promos = props.componentData.promos;
        }

        // Unified filtering for displayedPromos based on promoType and dates
        displayedPromos.value = promos.filter((promo) => {
            const format = "DD/MM/YYYY";
            let passesDate = true;

            if (promo.settings.start_date) {
                const startValid = moment(promo.settings.start_date, format).isSameOrBefore(currentDate);
                passesDate = passesDate && startValid;
            }
            if (promo.settings.end_date) {
                const endValid = moment(promo.settings.end_date, format).isSameOrAfter(currentDate);
                passesDate = passesDate && endValid;
            }

            // For promoType 'both', we don't filter by promo type; otherwise, check inclusion
            let passesPromoType = promoType === "both" ? true : promo.settings.promo_type.includes(promoType);

            return passesPromoType && passesDate;
        });
    };

    watch(
        () => siteStore.state.holidayFilterState,
        async (newVal) => {
            await getDisplayedPromos(
                props.componentData.settings.promo_type_visibility,
                props.componentData.content_source,
            );

            // console.log("Displayed Promos (Watcher): ", displayedPromos.value);
            // console.log("Promos (Watcher): ", filteredPromos.value);
        },
        {
            immediate: true,
        },
    );

    // console.log("Use Store?: ", props.useStore);
    // console.log("Displayed Promos: ", displayedPromos.value);
    // console.log("Promos: ", filteredPromos.value);
</script>
